.centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.vendor-biller-payment-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 4px;
}

.flex-info-item {
  display: grid;
  padding: 4px;
  grid-template-columns: repeat(3, 1fr);
}

.info-card-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.item-details-container {
  margin-top: 32px;
}

.item-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.currency-input {
  width: 100px;
}

.summary-details-container {
  display: flex;
  flex-direction: column;
  line-height: 2.5;
  margin-top: 32px;
  padding: 16px;
  position: relative;
  right: 0;
  width: 50%;
  margin-left: auto;
}

.summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  line-height: 1.5;
}

.summary-label {
  font-weight: bold;
  margin-right: 8px;
  flex: 1;
}

.summary-currency {
  margin-right: 8px;
  flex: 0;
}

.summary-value {
  margin-left: 8px;
  flex: 1;
  text-align: right;
}

.summary-divider {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 16px 0;
}
