.top-bar {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: -4vh;
  margin-bottom: -1vh;
}

.subheader-container {
  flex: 1;
}

.action-button {
  margin-left: 8px;
  flex: 0 1 auto;
  width: 100px;
}

.buttons-container {
  margin-left: 8px;
  flex: 0 1 auto;
  gap: 4px;
}
