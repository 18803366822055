.container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 16px;
  font-weight: bold;
}

.form {
  width: 100%;
  margin-top: 8px;
}

.submit {
  margin: 24px 0 16px;
}
